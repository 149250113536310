import * as tracking from 'dibs-tracking';

const heroList = 'Large Item Carousel';
const {
    trackingConstants: { ECOM_PRODUCT_CLICK, ECOM_PRODUCT_IMPRESSION },
    eventNameConstants: { EVENT_SELECT_ITEM, EVENT_VIEW_ITEM_LIST },
} = tracking;

function buildProductTrackingData({ item, index, list }) {
    const product = {
        dimension83: item.contemporaryTrackingString,
        url: item.link,
        name: item.title,
        position: index + 1,
        list,
    };

    return product;
}

function trackProductImpression({ items, startIndex }) {
    tracking.trackEcommerce({
        type: ECOM_PRODUCT_IMPRESSION,
        eventName: EVENT_VIEW_ITEM_LIST,
        products: items.map((item, index) =>
            buildProductTrackingData({
                item,
                index: startIndex + index,
                list: heroList,
            })
        ),
    });
}

function trackProductClick({ item, index }) {
    tracking.trackEcommerce({
        type: ECOM_PRODUCT_CLICK,
        eventName: EVENT_SELECT_ITEM,
        actionField: { list: heroList, itemId: item.itemId },
        products: [buildProductTrackingData({ item, index })],
    });
}

export { trackProductImpression, trackProductClick };
