import { FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { RecommendedItemsLazy } from 'dibs-recommended-for-you/exports/RecommendedItemsLazy';
import {
    trackModuleLocation,
    trackModule,
    trackItemClick,
    trackItemImpression,
    trackInitialDisplay,
    trackArrowClick,
    trackFavoriting,
} from 'dibs-recommended-for-you/exports/tracking';
import { stepInteractionConstants } from 'dibs-tracking';
import { addToEngagedItems } from 'dibs-buyer-layout/exports/engagedItems';

import { HpSharedRecommendedItems_componentModule$key } from './__generated__/HpSharedRecommendedItems_componentModule.graphql';
import { HpSharedRecommendedItems_viewer$key } from './__generated__/HpSharedRecommendedItems_viewer.graphql';

const componentModuleFragment = graphql`
    fragment HpSharedRecommendedItems_componentModule on RecommendedItemsModule {
        cmsDisplayTitle
    }
`;

const viewerFragment = graphql`
    fragment HpSharedRecommendedItems_viewer on Viewer {
        ...RecommendedItemsCarousel_viewer
    }
`;

type Props = {
    moduleIndex: number;
    totalModules: number;
    userId: string;
    isMobile: boolean;
    componentModule: HpSharedRecommendedItems_componentModule$key;
    viewer: HpSharedRecommendedItems_viewer$key;
};

export const HpSharedRecommendedItems: FC<Props> = ({
    moduleIndex,
    totalModules,
    isMobile,
    userId,
    componentModule: componentModuleRef,
    viewer: viewerRef,
}) => {
    const componentModule = useFragment(componentModuleFragment, componentModuleRef);
    const viewer = useFragment(viewerFragment, viewerRef);
    const { cmsDisplayTitle } = componentModule;
    const { STEP_INTERACTION_ITEM_ADDED, STEP_INTERACTION_ITEM_REMOVED } = stepInteractionConstants;

    return (
        <RecommendedItemsLazy
            viewer={viewer}
            userId={userId}
            isMobile={isMobile}
            onItemClick={({ item, index }) => {
                trackItemClick({ item, index, variant: 'homepage' });
                trackModuleLocation({
                    moduleIndex,
                    totalModules,
                    action: 'clicked homepage module',
                });
                trackModule({
                    action: 'homepage click',
                    label: cmsDisplayTitle,
                });
            }}
            onItemContentVisible={props => {
                trackItemImpression({ ...props, variant: 'homepage' });
            }}
            onCarouselContentVisible={props => {
                trackInitialDisplay(props);
            }}
            onArrowClick={props => {
                trackArrowClick(props);
            }}
            onFavorited={({ itemId }) => {
                trackFavoriting({
                    action: 'item added',
                    itemId,
                    label: 'homepage - recommended for you',
                    step_interaction_name: STEP_INTERACTION_ITEM_ADDED,
                });
                addToEngagedItems(itemId);
            }}
            onUnFavorited={({ itemId }) => {
                trackFavoriting({
                    action: 'item removed',
                    itemId,
                    label: 'homepage - recommended for you',
                    step_interaction_name: STEP_INTERACTION_ITEM_REMOVED,
                });
            }}
        />
    );
};
