/**
 * @generated SignedSource<<542c62d15bb612a1fd64cf7ac1293ea8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedHeroModule_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SharedFavoritesProvider_viewer">;
  readonly " $fragmentType": "HpSharedHeroModule_viewer";
};
export type HpSharedHeroModule_viewer$key = {
  readonly " $data"?: HpSharedHeroModule_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedHeroModule_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedHeroModule_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedFavoritesProvider_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "29bd721536ffb8798fe0c42edb56f19e";

export default node;
