/**
 * @generated SignedSource<<3f1e8d2eb4a50016f3c78ca08bc26fa4>>
 * @relayHash 280332761499cbe8f4f2deba4e69e100
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-misc/476.0.0-2024-08-20T10:46:32.636Z/HpAuctionItemsQuery

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpAuctionItemsQuery$variables = {
  auctionItemsCount: number;
  uriRef: string;
};
export type HpAuctionItemsQuery$data = {
  readonly viewer: {
    readonly itemSearch: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly item: {
            readonly browseUrl: string | null;
            readonly categoryCode: string | null;
            readonly contemporaryTrackingString: string;
            readonly firstPhotoWebPath: string | null;
            readonly localizedPdpUrl: string | null;
            readonly seller: {
              readonly serviceId: string | null;
            } | null;
            readonly serviceId: string | null;
            readonly title: string | null;
            readonly " $fragmentSpreads": FragmentRefs<"AuctionPricePaddle_item">;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"SharedFavoritesProvider_viewer">;
  };
};
export type HpAuctionItemsQuery = {
  response: HpAuctionItemsQuery$data;
  variables: HpAuctionItemsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "auctionItemsCount"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uriRef"
},
v2 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "auctionItemsCount"
  },
  {
    "kind": "Variable",
    "name": "uriRef",
    "variableName": "uriRef"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "size",
      "value": "master"
    }
  ],
  "kind": "ScalarField",
  "name": "firstPhotoWebPath",
  "storageKey": "firstPhotoWebPath(size:\"master\")"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizedPdpUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contemporaryTrackingString",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "browseUrl",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "categoryCode",
  "storageKey": null
},
v10 = [
  {
    "kind": "Literal",
    "name": "page",
    "value": "searchAndBrowse"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ConvertedAmountList",
    "kind": "LinkedField",
    "name": "convertedAmountList",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "HpAuctionItemsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "ItemSearchQueryConnection",
            "kind": "LinkedField",
            "name": "itemSearch",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ItemSearchQueryEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ItemSearchResult",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Item",
                        "kind": "LinkedField",
                        "name": "item",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Seller",
                            "kind": "LinkedField",
                            "name": "seller",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "args": (v10/*: any*/),
                            "kind": "FragmentSpread",
                            "name": "AuctionPricePaddle_item"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SharedFavoritesProvider_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "HpAuctionItemsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "ItemSearchQueryConnection",
            "kind": "LinkedField",
            "name": "itemSearch",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ItemSearchQueryEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ItemSearchResult",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Item",
                        "kind": "LinkedField",
                        "name": "item",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Seller",
                            "kind": "LinkedField",
                            "name": "seller",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isAuctionEnabled",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v10/*: any*/),
                            "concreteType": "AuctionSolution",
                            "kind": "LinkedField",
                            "name": "relevantAuctionSolution",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasReserve",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "reserveMet",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AuctionBidsConnection",
                                "kind": "LinkedField",
                                "name": "bids",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "totalResults",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "FinancialAmount",
                                "kind": "LinkedField",
                                "name": "highestBidAmount",
                                "plural": false,
                                "selections": (v12/*: any*/),
                                "storageKey": null
                              },
                              (v11/*: any*/)
                            ],
                            "storageKey": "relevantAuctionSolution(page:\"searchAndBrowse\")"
                          },
                          {
                            "alias": null,
                            "args": (v10/*: any*/),
                            "concreteType": "AuctionLot",
                            "kind": "LinkedField",
                            "name": "relevantAuctionLot",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "FinancialAmount",
                                "kind": "LinkedField",
                                "name": "buyItNowPrice",
                                "plural": false,
                                "selections": (v12/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "FinancialAmount",
                                "kind": "LinkedField",
                                "name": "openingBid",
                                "plural": false,
                                "selections": (v12/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "FinancialAmount",
                                "kind": "LinkedField",
                                "name": "reservePrice",
                                "plural": false,
                                "selections": (v12/*: any*/),
                                "storageKey": null
                              },
                              (v11/*: any*/)
                            ],
                            "storageKey": "relevantAuctionLot(page:\"searchAndBrowse\")"
                          },
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-misc/476.0.0-2024-08-20T10:46:32.636Z/HpAuctionItemsQuery",
    "metadata": {},
    "name": "HpAuctionItemsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "b2cb5710a2c60a384d9a063e2b4f6af5";

export default node;
