import { localStorage } from 'dibs-browser-storage';

const SHOP_LOCAL_KEY = 'shopLocal';
const SHOP_LOCAL_DISMISSAL_WAIT_PERIOD = 604800000; // 7 days in ms

type ShopLocalData = {
    timestamp: number;
};

export function hasRecentlyDismissedShopLocalPrompt(): boolean {
    const shopLocal = (localStorage.getItem(SHOP_LOCAL_KEY) as ShopLocalData) || {};
    const timeSinceDismissed = Date.now() - shopLocal.timestamp;
    return timeSinceDismissed < SHOP_LOCAL_DISMISSAL_WAIT_PERIOD;
}

export function dismissShopLocalPrompt(): void {
    localStorage.setItem(SHOP_LOCAL_KEY, {
        timestamp: Date.now(),
    });
}
