import { Environment } from 'react-relay';
import HpSharedHeroModule from './HpSharedHeroModule/HpSharedHeroModule';
import { HpSharedTopHeroBannerModule } from './HpSharedTopHeroBannerModule/HpSharedTopHeroBannerModule';
import { HpSharedRecommendedItems } from './HpSharedRecommendedItems/HpSharedRecommendedItems';
import { HpSharedLocationsModule } from './HpSharedLocationsModule/HpSharedLocationsModule';
import { HpSharedPersonalizedCategories } from './HpSharedPersonalizedCategories/HpSharedPersonalizedCategories';
import HpSharedPromoModulePrimary from './HpSharedPromoModule/HpSharedPromoModulePrimary';
import HpSharedPromoModuleSecondary from './HpSharedPromoModule/HpSharedPromoModuleSecondary';
import HpSharedCollectionsModule from './HpSharedCollectionsModule/HpSharedCollectionsModule';
import { HpSharedFeaturedModule } from './HpSharedFeaturedModule/HpSharedFeaturedModule';
import HpSharedEditorialModule from './HpSharedEditorialModule/HpSharedEditorialModule';
import { HpSharedShopByModule } from './HpSharedShopByModule/HpSharedShopByModule';
import HpSharedSkinnyBannerModule from './HpSharedSkinnyBannerModule/HpSharedSkinnyBannerModule';
import HpSharedCollectionSpotlightModule from './HpSharedCollectionSpotlightModule/HpSharedCollectionSpotlightModule';
import { HpSharedRecentlyViewedItems } from './HpSharedRecentlyViewedItems/HpSharedRecentlyViewedItems';
import { RecentlyViewedItemsProvider } from 'dibs-recent-history/exports/RecentlyViewedItemsProvider';

import { HpSharedRecentActivityModule } from './HpSharedRecentActivityModule/HpSharedRecentActivityModule';
import { HpAuctionItems } from './HpAuctionItems/HpAuctionItems';

import { HpSharedLayout_viewer$data } from './__generated__/HpSharedLayout_viewer.graphql';

type Homepage = NonNullable<HpSharedLayout_viewer$data['homepage']>;
type HomepageModules = NonNullable<NonNullable<Homepage['modules']>[0]>;
type Personalization = NonNullable<Homepage['personalization']>;

type Props = {
    componentModule: HomepageModules;
    userId: string;
    environment: Environment;
    key: string;
    promoPrimaryItemsCount: number;
    viewer: HpSharedLayout_viewer$data;
    isMobile: boolean;
    isTablet: boolean;
    isUser: boolean;
    itemsPerPage: number;
    personalization: Personalization;
    useLazyLoadImages: boolean;
    moduleIndex: number;
    totalModules: number;
    fetchFavorites: boolean;
};

/* eslint-disable react/prop-types */
export const componentModuleMap: Record<string, (payload: Props) => JSX.Element | null> = {
    skinnyBanner: ({ key }) => <HpSharedSkinnyBannerModule key={key} />,
    topHeroBanner: ({
        componentModule,
        key,
        useLazyLoadImages,
        isMobile,
        moduleIndex,
        totalModules,
    }) => (
        <HpSharedTopHeroBannerModule
            key={key}
            componentModule={componentModule}
            useLazyLoadImages={useLazyLoadImages}
            isMobile={isMobile}
            moduleIndex={moduleIndex}
            totalModules={totalModules}
        />
    ),
    hero: ({
        componentModule,
        key,
        useLazyLoadImages,
        moduleIndex,
        totalModules,
        userId,
        environment,
        viewer,
        fetchFavorites,
    }) => (
        <HpSharedHeroModule
            key={key}
            componentModule={componentModule}
            useLazyLoadImages={useLazyLoadImages}
            moduleIndex={moduleIndex}
            totalModules={totalModules}
            userId={userId}
            environment={environment}
            viewer={viewer || null}
            fetchFavorites={fetchFavorites}
        />
    ),
    collections: ({ componentModule, key, useLazyLoadImages, moduleIndex, totalModules }) => (
        <HpSharedCollectionsModule
            key={key}
            componentModule={componentModule}
            useLazyLoadImages={useLazyLoadImages}
            moduleIndex={moduleIndex}
            totalModules={totalModules}
        />
    ),
    promoPrimary: ({
        componentModule,
        key,
        useLazyLoadImages,
        isMobile,
        moduleIndex,
        totalModules,
    }) => (
        <HpSharedPromoModulePrimary
            key={key}
            componentModule={componentModule}
            useLazyLoadImages={useLazyLoadImages}
            isMobile={isMobile}
            moduleIndex={moduleIndex}
            totalModules={totalModules}
        />
    ),
    promoSecondary: ({
        componentModule,
        promoPrimaryItemsCount,
        key,
        useLazyLoadImages,
        isMobile,
        moduleIndex,
        totalModules,
    }) => (
        <HpSharedPromoModuleSecondary
            key={key}
            componentModule={componentModule}
            promoPrimaryItemsCount={promoPrimaryItemsCount}
            useLazyLoadImages={useLazyLoadImages}
            isMobile={isMobile}
            moduleIndex={moduleIndex}
            totalModules={totalModules}
        />
    ),
    featured: ({ componentModule, key, useLazyLoadImages, moduleIndex, totalModules }) => (
        <HpSharedFeaturedModule
            key={key}
            componentModule={componentModule}
            useLazyLoadImages={useLazyLoadImages}
            moduleIndex={moduleIndex}
            totalModules={totalModules}
        />
    ),
    editorial: ({ componentModule, key, useLazyLoadImages, moduleIndex, totalModules }) => (
        <HpSharedEditorialModule
            key={key}
            componentModule={componentModule}
            useLazyLoadImages={useLazyLoadImages}
            moduleIndex={moduleIndex}
            totalModules={totalModules}
        />
    ),
    shopBy: ({ componentModule, key, useLazyLoadImages, moduleIndex, totalModules }) => (
        <HpSharedShopByModule
            key={key}
            componentModule={componentModule}
            useLazyLoadImages={useLazyLoadImages}
            moduleIndex={moduleIndex}
            totalModules={totalModules}
        />
    ),
    locations: ({
        componentModule,
        viewer,
        key,
        itemsPerPage,
        isMobile,
        useLazyLoadImages,
        moduleIndex,
        totalModules,
    }) => (
        <HpSharedLocationsModule
            key={key}
            componentModule={componentModule}
            viewer={viewer || null}
            itemsPerPage={itemsPerPage}
            useLazyLoadImages={useLazyLoadImages}
            isMobile={isMobile}
            moduleIndex={moduleIndex}
            totalModules={totalModules}
        />
    ),
    recentlyViewed: ({
        isMobile,
        isTablet,
        key,
        itemsPerPage,
        componentModule,
        moduleIndex,
        totalModules,
    }) => {
        let maxItems = 12;
        if (isMobile) {
            itemsPerPage = 2.5;
        } else if (isTablet) {
            itemsPerPage = 5;
            maxItems = 10;
        }
        return (
            <RecentlyViewedItemsProvider key={key} itemsPerPage={itemsPerPage} maxItems={maxItems}>
                <HpSharedRecentlyViewedItems
                    isMobile={isMobile}
                    componentModule={componentModule}
                    moduleIndex={moduleIndex}
                    totalModules={totalModules}
                />
            </RecentlyViewedItemsProvider>
        );
    },
    recentActivity: ({
        key,
        viewer,
        userId,
        environment,
        fetchFavorites,
        isMobile,
        componentModule,
        moduleIndex,
        totalModules,
    }) => (
        <HpSharedRecentActivityModule
            key={key}
            viewer={viewer}
            userId={userId}
            environment={environment}
            fetchFavorites={fetchFavorites}
            isMobile={isMobile}
            componentModule={componentModule}
            moduleIndex={moduleIndex}
            totalModules={totalModules}
        />
    ),
    recommendedItems: ({
        viewer,
        componentModule,
        key,
        userId,
        isMobile,
        moduleIndex,
        totalModules,
    }) => (
        <HpSharedRecommendedItems
            key={key}
            userId={userId}
            viewer={viewer}
            componentModule={componentModule}
            isMobile={isMobile}
            moduleIndex={moduleIndex}
            totalModules={totalModules}
        />
    ),
    arrivals: ({
        isMobile,
        isUser,
        personalization,
        componentModule,
        key,
        moduleIndex,
        totalModules,
    }) =>
        isUser ? (
            <HpSharedPersonalizedCategories
                key={key}
                personalization={personalization}
                isMobile={isMobile}
                componentModule={componentModule}
                moduleIndex={moduleIndex}
                totalModules={totalModules}
            />
        ) : null,
    collectionSpotlight: ({
        componentModule,
        key,
        isMobile,
        useLazyLoadImages,
        moduleIndex,
        totalModules,
    }) => (
        <HpSharedCollectionSpotlightModule
            key={key}
            componentModule={componentModule}
            isMobile={isMobile}
            useLazyLoadImages={useLazyLoadImages}
            moduleIndex={moduleIndex}
            totalModules={totalModules}
        />
    ),
    auctionItems: ({
        isMobile,
        componentModule,
        isTablet,
        key,
        itemsPerPage,
        userId,
        fetchFavorites,
        useLazyLoadImages,
    }) => {
        return (
            <HpAuctionItems
                componentModule={componentModule}
                key={key}
                userId={userId}
                fetchFavorites={fetchFavorites}
                isMobile={isMobile}
                itemsPerPage={itemsPerPage}
                isTablet={isTablet}
                useLazyLoadImages={useLazyLoadImages}
            />
        );
    },
};
