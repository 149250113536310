import { FC, useCallback } from 'react';
import { graphql, useFragment, useLazyLoadQuery, useRelayEnvironment } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';
import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';
import {
    trackEvent,
    trackingConstants,
    eventNameConstants,
    stepInteractionConstants,
} from 'dibs-tracking';
import { Link } from 'dibs-elements/exports/Link';
import { Carousel } from 'dibs-carousel';
import { useCurrency } from 'dibs-buyer-layout/exports/useCurrency';
import { Swiper } from 'dibs-pinch-zoom-swipe';
import HeartWrapper from 'dibs-portfolio-heart';
import authModalLoader from 'dibs-buyer-layout/src/utils/AuthModalLoader';
import { AuctionPricePaddle } from 'dibs-auctions/exports/AuctionPricePaddle';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';
import { addToEngagedItems } from 'dibs-buyer-layout/exports/engagedItems';

import HpLazyImageWrapper from '../HpLazyImageWrapper/HpLazyImageWrapper';
import SharedFavoritesProvider from '../../sharedComponents/SharedFavoritesProvider';
import { HpSharedCarouselTracking } from '../HpSharedCarouselTracking/HpSharedCarouselTracking';
import ResponsiveImage from '../../homepage/ResponsiveImage';
import {
    trackCarouselItems,
    trackImpressions,
    trackArrowClick,
    trackInitialDisplay,
    trackFavoriting,
} from '../helpers/carouselTracking';

import { AUCTION_ITEM_PAGE_COUNT } from '../constants';

import type { SharedFavoritesProps } from '../HpSharedHeroModule/HpSharedHeroModule';

import { HpAuctionItems_componentModule$key } from './__generated__/HpAuctionItems_componentModule.graphql';
import { HpAuctionItemsQuery } from './__generated__/HpAuctionItemsQuery.graphql';

import styles from './HpAuctionItems.scss';

export type Props = {
    componentModule: HpAuctionItems_componentModule$key;
    itemsPerPage: number;
    isMobile: boolean;
    isTablet: boolean;
    userId?: string;
    useLazyLoadImages: boolean;
    fetchFavorites?: boolean;
};

const ACTION_LABEL = 'auction';
const { STEP_INTERACTION_ITEM_ADDED, STEP_INTERACTION_ITEM_REMOVED } = stepInteractionConstants;

export const HpAuctionItems: FC<Props> = ({
    componentModule: componentModuleRef,
    itemsPerPage,
    isMobile,
    isTablet,
    userId,
    useLazyLoadImages,
    fetchFavorites,
}) => {
    const environment = useRelayEnvironment();
    const componentModule = useFragment(
        graphql`
            fragment HpAuctionItems_componentModule on AuctionItemsModule {
                title
                viewAllLink
            }
        `,
        componentModuleRef
    );

    const { title: moduleTitle, viewAllLink } = componentModule || {};

    let adjustedItemsPerPage = itemsPerPage;

    if (isMobile) {
        adjustedItemsPerPage = 2.5;
    } else if (isTablet) {
        adjustedItemsPerPage = 5;
    }

    const viewAllPageUriRef = viewAllLink || '/auctions/';

    const { viewer } = useLazyLoadQuery<HpAuctionItemsQuery>(
        graphql`
            query HpAuctionItemsQuery($uriRef: String!, $auctionItemsCount: Int!) {
                viewer {
                    itemSearch(uriRef: $uriRef, first: $auctionItemsCount) {
                        edges {
                            node {
                                item {
                                    serviceId
                                    title
                                    firstPhotoWebPath(size: master)
                                    localizedPdpUrl
                                    contemporaryTrackingString
                                    browseUrl
                                    categoryCode
                                    seller {
                                        serviceId
                                    }
                                    ...AuctionPricePaddle_item @arguments(page: searchAndBrowse)
                                }
                            }
                        }
                    }
                    ...SharedFavoritesProvider_viewer
                }
            }
        `,
        {
            uriRef: viewAllPageUriRef,
            auctionItemsCount: itemsPerPage * AUCTION_ITEM_PAGE_COUNT,
        }
    );

    const { itemSearch } = viewer || {};
    const landingPageItems =
        (itemSearch?.edges || [])
            .map(itemNode => itemNode?.node?.item || null)
            .filter(filterNulls) || [];
    const items = landingPageItems || [];
    const { currency } = useCurrency();

    const authModalShow = useCallback((favoriteItem: unknown) => {
        authModalLoader
            ?.show({
                flow: authModalLoader.constants.SAVE_ITEM_FLOW,
            })
            .then(favoriteItem);
    }, []);

    const renderItem = useCallback(
        ({ index, favorites }: { index: number; favorites: SharedFavoritesProps['favorites'] }) => {
            const item = items[index];
            if (!item) {
                return null;
            }
            const { firstPhotoWebPath, localizedPdpUrl, title } = item;
            const { ECOM_PRODUCT_CLICK } = trackingConstants;
            const { EVENT_SELECT_ITEM } = eventNameConstants;

            return (
                <div key={index} className={styles.itemTileWrapper}>
                    <div className={styles.itemTileInner} key={item.serviceId}>
                        <Link
                            href={localizedPdpUrl || ''}
                            dataTn="auction-module-item"
                            onClick={() => {
                                trackCarouselItems({
                                    actionLabel: ACTION_LABEL,
                                    type: ECOM_PRODUCT_CLICK,
                                    items: [item],
                                    startIndex: index + 1, // Tracking needs non zero index position.
                                    variant: 'homepage',
                                    eventName: EVENT_SELECT_ITEM,
                                });
                            }}
                        >
                            <HpLazyImageWrapper
                                useLazyLoad={useLazyLoadImages}
                                className={styles.imageContainer}
                            >
                                <ResponsiveImage
                                    className={styles.image}
                                    altText={title || item.serviceId || ''}
                                    src={firstPhotoWebPath || ''}
                                    srcSetWidths={[100, 120, 150]}
                                />
                            </HpLazyImageWrapper>
                            <AuctionPricePaddle item={item} showPill currency={currency} />
                        </Link>
                        <HeartWrapper
                            itemId={item.serviceId}
                            loadPortfolioData={favorites.loadPortfolioData}
                            viewer={favorites.viewer || null}
                            userId={favorites.userId || null}
                            userIds={favorites.userIds || []}
                            selectedItemIds={favorites.selectedItemIds}
                            authModalShow={authModalShow}
                            theme="dark"
                            onFavorited={() => {
                                trackFavoriting({
                                    action: 'item added',
                                    itemId: item.serviceId,
                                    actionLabel: 'auctions',
                                    step_interaction_name: STEP_INTERACTION_ITEM_ADDED,
                                });
                                addToEngagedItems(item.serviceId);
                            }}
                            onUnFavorited={() =>
                                trackFavoriting({
                                    action: 'item removed',
                                    itemId: item.serviceId,
                                    actionLabel: 'auctions',
                                    step_interaction_name: STEP_INTERACTION_ITEM_REMOVED,
                                })
                            }
                            className={styles.heart}
                            buttonClass={styles.heartButton}
                        />
                    </div>
                </div>
            );
        },
        [items, authModalShow, currency, useLazyLoadImages]
    );

    if (!items?.length) {
        return null;
    }

    const favoritesProviderProps = {
        userId,
        environment,
        viewer,
        itemIds: items.map(item => item?.serviceId),
        fetchFavorites: fetchFavorites && items.length > 0,
    };

    return (
        <SharedFavoritesProvider {...favoritesProviderProps}>
            {({ favorites }: SharedFavoritesProps) => (
                <div className={styles.wrapper}>
                    <div className={styles.header}>
                        <HeadingLevel>
                            {Heading => (
                                <Heading className={styles.title} dataTn="auction-items-title">
                                    {moduleTitle || (
                                        <FormattedMessage
                                            id="homepage.modules.auctionItems.title"
                                            defaultMessage="Items at Auction"
                                        />
                                    )}
                                </Heading>
                            )}
                        </HeadingLevel>
                        <Link
                            href={viewAllPageUriRef}
                            target="_blank"
                            onClick={e =>
                                trackEvent(
                                    {
                                        category: 'promo interaction',
                                        action: 'auction view all clicked',
                                        label: '[homepage]',
                                    },
                                    e
                                )
                            }
                            className={styles.link}
                        >
                            <FormattedMessage
                                id="homepage.modules.auctionItems.viewMoreCta"
                                defaultMessage="View All"
                            />
                        </Link>
                    </div>

                    <HpSharedCarouselTracking
                        items={items}
                        itemsPerPage={adjustedItemsPerPage}
                        onItemsImpression={({ visibleItems, index }) =>
                            trackImpressions({
                                visibleItems,
                                index,
                                actionLabel: ACTION_LABEL,
                            })
                        }
                        onInitialDisplay={() =>
                            trackInitialDisplay({
                                itemsReturned: items?.length,
                                actionLabel: ACTION_LABEL,
                            })
                        }
                    >
                        {({ handleIndexChange, handlePageChange }) =>
                            isMobile ? (
                                <Swiper
                                    itemsPerPage={adjustedItemsPerPage}
                                    onPageChange={(page: number) => handlePageChange(page)}
                                    classNames={{
                                        wrapper: styles.swiperWrapper,
                                    }}
                                >
                                    {items.map((item, index) => renderItem({ index, favorites }))}
                                </Swiper>
                            ) : (
                                <Carousel
                                    slideCarouselItems
                                    step={adjustedItemsPerPage}
                                    itemsPerPage={adjustedItemsPerPage}
                                    totalItems={items.length}
                                    onIndexChange={handleIndexChange}
                                    renderItem={({ index }: { index: number }) =>
                                        renderItem({
                                            index,
                                            favorites,
                                        })
                                    }
                                    classNames={{
                                        arrowLeft: styles.arrowLeft,
                                        arrowRight: styles.arrowRight,
                                    }}
                                    onLeftArrowClick={() =>
                                        trackArrowClick({
                                            direction: 'left',
                                            actionLabel: ACTION_LABEL,
                                        })
                                    }
                                    onRightArrowClick={() =>
                                        trackArrowClick({
                                            direction: 'right',
                                            actionLabel: ACTION_LABEL,
                                        })
                                    }
                                />
                            )
                        }
                    </HpSharedCarouselTracking>
                </div>
            )}
        </SharedFavoritesProvider>
    );
};
