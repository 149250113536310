import { FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

import { useHomePageContext } from '../HomePageContext';
import HpLazyImageWrapper from '../HpLazyImageWrapper/HpLazyImageWrapper';
import ResponsiveImage from '../ResponsiveImage';
import HpSharedHeroBannerText from '../HpSharedHeroBannerText/HpSharedHeroBannerText';
import classnames from 'classnames';
import { Link } from 'dibs-elements/exports/Link';
import { HpSharedVideoTile } from '../HpSharedVideoTile/HpSharedVideoTile';

import { HpSharedSharedHeroBanner_topItem$data as TopItem } from './__generated__/HpSharedSharedHeroBanner_topItem.graphql';
import { HpSharedSharedHeroBanner_promoItem$data as PromoItem } from './__generated__/HpSharedSharedHeroBanner_promoItem.graphql';

import styles from './HpSharedSharedHeroBanner.scss';

type Props = {
    isVisible: boolean;
    onClick: () => void;
    onVideoStateChange?: () => void;
    useLazyLoadImages?: boolean;
    videoPlaying?: boolean;
} & (
    | {
          topItem: TopItem;
          promoItem?: never;
      }
    | {
          topItem?: never;
          promoItem: PromoItem;
      }
);

const HpSharedSharedHeroBanner: FC<Props> = ({
    isVisible,
    onClick,
    onVideoStateChange = () => {},
    useLazyLoadImages,
    topItem,
    promoItem,
    videoPlaying = false,
}) => {
    const {
        cmsId,
        imageUrl,
        mobileImageUrl,
        imageHeight = 0,
        imageWidth = 0,
        mobileImageHeight = 0,
        mobileImageWidth = 0,
        linkData = null,
        cta = null,
        heroBannerSettings = null,
    } = topItem || promoItem || {};

    const { videoUrl } = topItem || {};

    const { isMobile } = useHomePageContext();

    const { backgroundColor, bannerImageWidth, contentPosition } = heroBannerSettings || {};
    const isFullWidth = bannerImageWidth === 'full';
    const isReverse = contentPosition === 'right';
    return (
        <div
            className={classnames(styles.contentContainer, {
                [styles.fullWidth]: isFullWidth,
                [styles.reverse]: isReverse,
                [styles.visible]: isVisible,
                [styles.border]: !backgroundColor && !isFullWidth,
            })}
            style={{
                backgroundColor: backgroundColor || undefined,
            }}
        >
            <HpSharedHeroBannerText
                onClick={onClick}
                className={styles.textContainer}
                heroBannerSettings={heroBannerSettings}
                linkData={linkData}
                cta={cta}
            />

            {videoUrl ? (
                <HpSharedVideoTile
                    videoUrl={videoUrl}
                    posterOverride={imageUrl || mobileImageUrl || ''}
                    onVideoStateChange={onVideoStateChange}
                    isMobile={isMobile}
                    videoPlaying={videoPlaying}
                />
            ) : (
                <HpLazyImageWrapper
                    className={classnames(styles.imageContainer, {
                        [styles.fullWidth]: isFullWidth,
                    })}
                    useLazyLoad={useLazyLoadImages}
                    imageHeight={mobileImageHeight || imageHeight}
                    imageWidth={mobileImageWidth || imageWidth}
                >
                    <ResponsiveImage
                        ariaHidden
                        className={classnames(styles.image, {
                            [styles.fullWidth]: isFullWidth,
                        })}
                        src={imageUrl || mobileImageUrl || ''}
                    />
                </HpLazyImageWrapper>
            )}

            {/* eslint-disable-next-line react/forbid-elements, jsx-a11y/anchor-has-content */}
            <Link
                // this link is redundant with the cta in HpSharedHeroBannerText. do not add
                // label/children, hide from a11y-tree, and prevent keyboard focus.
                ariaHidden
                key={cmsId}
                tabIndex={-1}
                href={linkData?.path || undefined}
                className={classnames(styles.overlayLink, {
                    [styles.right]: videoUrl && !isMobile && !isReverse,
                    [styles.left]: videoUrl && !isMobile && isReverse,
                    [styles.top]: videoUrl && isMobile,
                })}
                onClick={onClick}
            />
        </div>
    );
};

export const HpSharedTopHeroBannerItem = createFragmentContainer(HpSharedSharedHeroBanner, {
    topItem: graphql`
        fragment HpSharedSharedHeroBanner_topItem on TopHeroBannerItem {
            cmsId
            ... on TopHeroBannerItem @skip(if: $isMobile) {
                imageUrl
                imageHeight
                imageWidth
            }
            ... on TopHeroBannerItem @include(if: $isMobile) {
                mobileImageHeight
                mobileImageWidth
                mobileImageUrl
            }
            linkData {
                path
                ...HpSharedHeroBannerText_linkData
            }
            heroBannerSettings {
                ...HpSharedHeroBannerText_heroBannerSettings
                contentPosition
                backgroundColor
                bannerImageWidth
            }
            cta
            isFullBleedImage
            format
            videoUrl
        }
    `,
});

export const HpSharedPromoHeroItem = createFragmentContainer(HpSharedSharedHeroBanner, {
    promoItem: graphql`
        fragment HpSharedSharedHeroBanner_promoItem on PromoItem {
            cmsId
            ... on PromoItem @skip(if: $isMobile) {
                imageUrl
                imageHeight
                imageWidth
            }
            ... on PromoItem @include(if: $isMobile) {
                mobileImageHeight
                mobileImageWidth
                mobileImageUrl
            }
            linkData {
                path
                ...HpSharedHeroBannerText_linkData
            }
            heroBannerSettings {
                ...HpSharedHeroBannerText_heroBannerSettings
                contentPosition
                backgroundColor
                bannerImageWidth
            }
            cta
            isFullBleedImage
            format
        }
    `,
});
