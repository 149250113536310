import { FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import authModalLoader from 'dibs-buyer-layout/src/utils/AuthModalLoader';
import { RecentlyViewedItemsLazy } from 'dibs-recent-history/exports/RecentlyViewedItemsLazy';

import {
    trackViewMoreClick,
    trackItemClick,
    trackItemImpression,
} from 'dibs-recent-history/exports/recentHistoryTracking';
import { trackModule, trackModuleLocation } from '../helpers/moduleLocationTracking';
import { trackInitialDisplay } from '../helpers/carouselTracking';
import { useCurrency } from 'dibs-buyer-layout/exports/useCurrency';
import { useHomePageContext } from '../HomePageContext';

import { HpSharedRecentlyViewedItems_componentModule$key } from './__generated__/HpSharedRecentlyViewedItems_componentModule.graphql';

import styles from './HpSharedRecentlyViewedItems.scss';

const ACTION_LABEL = 'recently viewed';

const componentModuleFragment = graphql`
    fragment HpSharedRecentlyViewedItems_componentModule on RecentlyViewedModule {
        cmsDisplayTitle
    }
`;

export const HpSharedRecentlyViewedItems: FC<{
    moduleIndex: number;
    totalModules: number;
    isMobile: boolean;
    componentModule: HpSharedRecentlyViewedItems_componentModule$key;
}> = ({ moduleIndex, totalModules, isMobile, componentModule: componentModuleRef }) => {
    const componentModule = useFragment(componentModuleFragment, componentModuleRef);
    const { cmsDisplayTitle } = componentModule;
    const { currency } = useCurrency();
    const { isBot } = useHomePageContext();

    return (
        <RecentlyViewedItemsLazy
            centerTitle
            isMobile={isMobile}
            wrapperClassName={styles.recentlyViewed}
            authModalLoader={authModalLoader}
            actionLabel={ACTION_LABEL}
            trackViewMoreClick={trackingProps => {
                trackViewMoreClick(trackingProps);
                trackModule(cmsDisplayTitle);
            }}
            trackItemClick={trackingProps => {
                trackItemClick({ ...trackingProps, variant: 'homepage' });
                trackModuleLocation({
                    label: 'recently viewed module',
                    moduleIndex,
                    totalModules,
                });
                trackModule(cmsDisplayTitle);
            }}
            trackItemImpression={trackingProps => {
                trackItemImpression({ ...trackingProps, variant: 'homepage' });
            }}
            onInitialDisplay={({ items }) => {
                trackInitialDisplay({ itemsReturned: items.length, actionLabel: ACTION_LABEL });
            }}
            currency={currency}
            isBot={isBot}
        />
    );
};
