import { useState, useEffect } from 'react';
import { getAbTestV2 } from 'dibs-buyer-layout/exports/clientABTestV2';

export const isHpAppDownloadPromptVariant = (): boolean =>
    getAbTestV2('hpAppDownloadPrompt')?.variant === 'variant';

export const useHpAppDownloadPromptVariant = (): boolean | null => {
    const [isHpAppDownloadPromptClient, setIsHpAppDownloadPromptClient] = useState<boolean | null>(
        null
    );

    useEffect(() => {
        setIsHpAppDownloadPromptClient(isHpAppDownloadPromptVariant());
    }, []);

    return isHpAppDownloadPromptClient;
};
