import { SHOW_SHOP_LOCAL_TOAST } from './shopLocal/shopLocalConstants';

type NotificationsReducerAction = {
    type: string;
    showShopLocalToast: boolean;
};

export type NotificationsReducerState = {
    notifications: {
        SHOW_SHOP_LOCAL_TOAST?: boolean;
    };
};

export const notificationsReducer = (
    state: NotificationsReducerState,
    action: NotificationsReducerAction
): NotificationsReducerState => {
    switch (action.type) {
        case SHOW_SHOP_LOCAL_TOAST:
            return {
                ...state,
                notifications: { [SHOW_SHOP_LOCAL_TOAST]: action.showShopLocalToast },
            };
        default:
            return state || { notifications: {} };
    }
};
