import { lazy, Suspense, FC, useEffect, useState } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { useSelector, useStore } from 'react-redux';
import { mobile } from 'dibs-client-check';

import { SHOW_SHOP_LOCAL_TOAST } from './shopLocalConstants';
import { NotificationsReducerState } from '../notificationsReducer';
import { useHpAppDownloadPromptVariant } from '../../homepage/helpers/useHpAppDownloadPromptVariant';
import { addLoginHandlers } from 'dibs-buyer-layout/exports/loginHandlers';
import { shouldShowShopLocal } from './shopLocalConfig';

const ShopLocalToast = lazy(
    () =>
        import(
            /* webpackChunkName: "ShopLocalToast" */
            './components/ShopLocalToast'
        )
);

const AppDownloadToast = lazy(
    () =>
        import(
            /* webpackChunkName: "AppDownloadToast" */
            './components/AppDownloadToast'
        )
);

const TabletAppDownloadToast = lazy(
    () =>
        import(
            /* webpackChunkName: "TabletAppDownloadToast" */
            './components/TabletAppDownloadToast'
        )
);

type ReduxState = { notificationsReducer: NotificationsReducerState };

export const ShopLocalNotificationLoader: FC<{ isTablet?: boolean }> = ({ isTablet = false }) => {
    const relayEnvironment = useRelayEnvironment();
    const store = useStore();
    const [showShopLocal, setShowShopLocal] = useState(true);

    useEffect(() => {
        addLoginHandlers(async () => {
            const result = await shouldShowShopLocal({ relayEnvironment, store });
            setShowShopLocal(result);
        });
    }, [relayEnvironment, store]);

    const isHpAppDownloadPromptVariant = useHpAppDownloadPromptVariant();

    const notifications = useSelector(
        (state: ReduxState) => state.notificationsReducer?.notifications || {}
    );

    if (
        !notifications[SHOW_SHOP_LOCAL_TOAST] ||
        isHpAppDownloadPromptVariant === null ||
        !showShopLocal
    ) {
        return null;
    }

    if (isHpAppDownloadPromptVariant) {
        return (
            <Suspense fallback="">
                {isTablet || mobile.iPad() ? <TabletAppDownloadToast /> : <AppDownloadToast />}
            </Suspense>
        );
    }

    return (
        <Suspense fallback="">
            <ShopLocalToast />
        </Suspense>
    );
};
