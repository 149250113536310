import { FC } from 'react';
import { Environment } from 'react-relay';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { Carousel } from 'dibs-carousel';
import { Swiper } from 'dibs-pinch-zoom-swipe';

import { HpSharedModuleContainer } from '../HpSharedModuleContainer/HpSharedModuleContainer';
import { SharedCarouselDot } from '../../sharedComponents/SharedCarouselDot/SharedCarouselDot';
import LargeItemCarouselItem from './LargeItemCarouselItem';
import { HpSharedHeader } from '../HpSharedHeader/HpSharedHeader';

import { RenderIn, device } from '../../utils/RenderIn';
import { HpSharedCarouselTracking } from '../HpSharedCarouselTracking/HpSharedCarouselTracking';
import { trackProductImpression, trackProductClick } from './helpers/HeroTracking';
import { InViewport } from 'dibs-in-viewport/exports/InViewport';
import { TABLET_ITEMS_PER_PAGE } from '../constants';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';
import { filterFalsy } from 'dibs-ts-utils/exports/filterFalsy';

import {
    trackPromoClick,
    trackPromoImpressions,
    buildPromoTrackingObject,
} from '../helpers/promoTrackingHelpers';
import { trackModuleLocation, trackModule } from '../helpers/moduleLocationTracking';

import SharedFavoritesProvider from '../../sharedComponents/SharedFavoritesProvider';

import { HpSharedHeroModule_viewer$data } from './__generated__/HpSharedHeroModule_viewer.graphql';

import styles from './HpSharedHeroModule.scss';

/**
 *  Removes items residue because we want to make sure that all of the carousel pages are full
 */
function removeItemsResidue({
    totalItems,
    itemsPerPage,
}: {
    totalItems: number;
    itemsPerPage: number;
}): number {
    const residue = totalItems % itemsPerPage;

    if (residue !== 0) {
        return totalItems - residue;
    }

    return totalItems;
}

import { HpSharedHeroModule_componentModule$data } from './__generated__/HpSharedHeroModule_componentModule.graphql';

type CarouselItems = NonNullable<HpSharedHeroModule_componentModule$data['items']>;

type Props = {
    userId: string;
    environment: Environment;
    viewer: HpSharedHeroModule_viewer$data;
    componentModule: HpSharedHeroModule_componentModule$data;
    useLazyLoadImages: boolean;
    moduleIndex: number;
    totalModules: number;
    fetchFavorites: boolean;
};

export type SharedFavoritesProps = {
    favorites: {
        loadPortfolioData: boolean;
        viewer: HpSharedHeroModule_viewer$data;
        userId: string;
        userIds: string[];
        selectedItemIds: string[];
    };
};

const HpSharedHeroModule: FC<Props> = ({
    userId,
    environment,
    viewer,
    componentModule,
    useLazyLoadImages,
    moduleIndex,
    totalModules,
    fetchFavorites,
}) => {
    if (!componentModule) {
        return null;
    }

    const { title, cmsDisplayTitle, viewMoreLink } = componentModule;
    const items = (componentModule.items || [])
        .filter(filterNulls)
        .filter(item => filterFalsy(item.itemId));
    if (!items.length) {
        return null;
    }

    const onItemsImpression = ({
        visibleItems,
        index,
    }: {
        visibleItems: CarouselItems;
        index: number;
    }): void => {
        trackProductImpression({
            items: visibleItems,
            startIndex: index,
        });
        const promoTrackingObjects = visibleItems.map(item => {
            const trackingItem = {
                ...item,
                linkData: {
                    path: item?.link,
                },
            };
            return buildPromoTrackingObject({
                id: 'large-item-carousel',
                name: 'Large Item Carousel',
                item: trackingItem,
                index,
            });
        });
        trackPromoImpressions(promoTrackingObjects);
    };

    const handleClick = (index: number): void => {
        const item = items[index];
        trackProductClick({
            item,
            index,
        });
        const promoTrackingObject = buildPromoTrackingObject({
            id: 'large-item-carousel',
            name: 'Large Item Carousel',
            item: {
                ...item,
                linkData: {
                    path: item?.link,
                },
            },
            index,
        });
        trackPromoClick(promoTrackingObject);

        trackModuleLocation({
            label: 'large item carousel module',
            moduleIndex,
            totalModules,
        });
        trackModule(cmsDisplayTitle);
    };

    const favoritesProviderProps = {
        userId,
        environment,
        viewer,
        itemIds: items.map(item => item.itemId),
        fetchFavorites: fetchFavorites && items.length > 0,
    };

    return (
        <SharedFavoritesProvider {...favoritesProviderProps}>
            {({ favorites }: SharedFavoritesProps) => (
                <HpSharedModuleContainer addTopGap={!title}>
                    <HpSharedHeader
                        title={title}
                        viewMoreLink={viewMoreLink || '/collections/editors-picks/'}
                        dataTn="editors-picks"
                    />
                    <RenderIn deviceTypes={[device.DESKTOP, device.TABLET]}>
                        {({ currentDevice }: { currentDevice: string }) => {
                            const itemsPerPage =
                                currentDevice === device.DESKTOP ? TABLET_ITEMS_PER_PAGE : 3;
                            const totalItems = removeItemsResidue({
                                totalItems: items.length,
                                itemsPerPage,
                            });
                            return (
                                <HpSharedCarouselTracking
                                    items={items}
                                    itemsPerPage={itemsPerPage}
                                    onItemsImpression={onItemsImpression}
                                >
                                    {({ handleIndexChange }) => (
                                        <InViewport>
                                            {({ inViewport }: { inViewport: boolean }) => (
                                                <Carousel
                                                    classNames={{
                                                        listWrapper: styles.listWrapper,
                                                    }}
                                                    isAutoRun={
                                                        inViewport && totalItems > itemsPerPage
                                                    }
                                                    dataTn="hero-items-carousel"
                                                    showDots={totalItems > itemsPerPage}
                                                    hideArrows
                                                    totalItems={totalItems}
                                                    itemsPerPage={itemsPerPage}
                                                    step={itemsPerPage}
                                                    renderItem={({
                                                        index,
                                                        isVisible,
                                                    }: {
                                                        index: number;
                                                        isVisible: boolean;
                                                    }) => (
                                                        <LargeItemCarouselItem
                                                            item={items[index]}
                                                            isVisible={isVisible}
                                                            useLazyLoadImages={useLazyLoadImages}
                                                            onClick={() => handleClick(index)}
                                                            userId={userId}
                                                            favorites={favorites}
                                                        />
                                                    )}
                                                    onIndexChange={handleIndexChange}
                                                    renderDot={({
                                                        isCurrentDot,
                                                    }: {
                                                        isCurrentDot?: boolean;
                                                    } = {}) => (
                                                        <SharedCarouselDot
                                                            isActive={isCurrentDot}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </InViewport>
                                    )}
                                </HpSharedCarouselTracking>
                            );
                        }}
                    </RenderIn>

                    <RenderIn deviceTypes={[device.MOBILE]}>
                        {() => {
                            const itemsPerPage = 1.5;

                            return (
                                <HpSharedCarouselTracking
                                    items={items}
                                    itemsPerPage={itemsPerPage}
                                    onItemsImpression={onItemsImpression}
                                >
                                    {({ handlePageChange }) => (
                                        <Swiper
                                            itemsPerPage={itemsPerPage}
                                            onPageChange={handlePageChange}
                                            classNames={{ item: styles.swiperItem }}
                                        >
                                            {items.map((item, index) => (
                                                <LargeItemCarouselItem
                                                    key={item.cmsId || index}
                                                    item={item}
                                                    useLazyLoadImages={useLazyLoadImages}
                                                    onClick={() => handleClick(index)}
                                                    isVisible
                                                    userId={userId}
                                                    favorites={favorites}
                                                />
                                            ))}
                                        </Swiper>
                                    )}
                                </HpSharedCarouselTracking>
                            );
                        }}
                    </RenderIn>
                </HpSharedModuleContainer>
            )}
        </SharedFavoritesProvider>
    );
};

export default createFragmentContainer(HpSharedHeroModule, {
    viewer: graphql`
        fragment HpSharedHeroModule_viewer on Viewer {
            ...SharedFavoritesProvider_viewer
        }
    `,
    componentModule: graphql`
        fragment HpSharedHeroModule_componentModule on HeroModule {
            title
            cmsDisplayTitle
            viewMoreLink
            items {
                itemId
                cmsId
                link
                imageUrl
                title
                contemporaryTrackingString
            }
        }
    `,
});
