import { graphql, fetchQuery_DEPRECATED as fetchQuery, Environment } from 'react-relay/legacy';
import { Dispatch, Store } from 'redux';
import serverVars from 'server-vars';
import { getBuyerId } from 'dibs-cookie-jar';
import { mobile } from 'dibs-client-check';

import { isHpAppDownloadPromptVariant } from '../../homepage/helpers/useHpAppDownloadPromptVariant';
import { SHOW_SHOP_LOCAL_TOAST } from './shopLocalConstants';
import { hasRecentlyDismissedShopLocalPrompt } from './shopLocalHelpers';

import {
    shopLocalConfigQuery,
    shopLocalConfigQuery$data,
} from './__generated__/shopLocalConfigQuery.graphql';

export const shouldShowShopLocal = async ({
    relayEnvironment,
    store,
}: {
    relayEnvironment: Environment;
    store: Store;
}): Promise<boolean> => {
    // dispatch action not to overlap shopLocal and footer marketing banners
    const hideMarketingBanner = (value: boolean): void => {
        store.dispatch({
            type: SHOW_SHOP_LOCAL_TOAST,
            showShopLocalToast: value,
        });
    };

    if (hasRecentlyDismissedShopLocalPrompt()) {
        hideMarketingBanner(false);
        return Promise.resolve(false);
    }

    const isHpAppDownloadPrompt = isHpAppDownloadPromptVariant();

    const userId = getBuyerId(document.cookie) || '';
    const fetchUser = !!userId && isHpAppDownloadPrompt;

    const response: shopLocalConfigQuery$data = await fetchQuery<shopLocalConfigQuery>(
        relayEnvironment,
        graphql`
            query shopLocalConfigQuery($userId: String!, $fetchUser: Boolean!) {
                viewer {
                    shopLocalNotificationFeatureFlag: featureFlag(feature: "shopLocalNotification")
                    user(userId: $userId) @include(if: $fetchUser) {
                        hasUsedAppInLastDays(days: 90)
                    }
                }
            }
        `,
        { userId, fetchUser }
    );

    let showShopLocal = false;
    if (isHpAppDownloadPrompt) {
        showShopLocal =
            Boolean(response.viewer.shopLocalNotificationFeatureFlag) &&
            (serverVars.get('settings.isDesktop') || mobile.iPad()) &&
            !response.viewer.user?.hasUsedAppInLastDays;
    } else {
        showShopLocal =
            Boolean(response.viewer.shopLocalNotificationFeatureFlag) &&
            !serverVars.get('settings.isMobile');
    }

    hideMarketingBanner(showShopLocal);

    return showShopLocal;
};

export const shopLocalConfig = {
    name: 'shopLocal',
    rule: shouldShowShopLocal,
    action: (): unknown => (dispatch: Dispatch) => {
        dispatch({
            type: SHOW_SHOP_LOCAL_TOAST,
            showShopLocalToast: true,
        });
    },
    priority: 50,
};
