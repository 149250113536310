/**
 * @generated SignedSource<<2c9eb93e6206899aef9ca91fa4534c9e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpAuctionItems_componentModule$data = {
  readonly title: string | null;
  readonly viewAllLink: string | null;
  readonly " $fragmentType": "HpAuctionItems_componentModule";
};
export type HpAuctionItems_componentModule$key = {
  readonly " $data"?: HpAuctionItems_componentModule$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpAuctionItems_componentModule">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpAuctionItems_componentModule",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewAllLink",
      "storageKey": null
    }
  ],
  "type": "AuctionItemsModule",
  "abstractKey": null
};

(node as any).hash = "bfb26a717a8795cf218284d6e129fe7d";

export default node;
