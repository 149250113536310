import { MOBILE_ITEMS_PER_PAGE, TABLET_ITEMS_PER_PAGE, DESKTOP_ITEMS_PER_PAGE } from '../constants';

export const getItemsPerPage = ({ isMobile, isTablet, itemsPerRow }) => {
    if (isMobile) {
        return MOBILE_ITEMS_PER_PAGE;
    } else if (isTablet) {
        return TABLET_ITEMS_PER_PAGE;
    }

    return itemsPerRow || DESKTOP_ITEMS_PER_PAGE;
};
