import { FC, ReactNode } from 'react';
import { FormattedMessage } from 'dibs-react-intl';

const AuthenticityGuarantee: FC = () => (
    <FormattedMessage
        id="useBuyerIncentivesMessages.authenticityGuarantee"
        defaultMessage="Authenticity Guarantee"
    />
);

const Cancellation: FC = () => (
    <FormattedMessage
        id="useBuyerIncentivesMessages.cancelationPolicy"
        defaultMessage="24-Hour Cancellation"
    />
);

export const MoneyBack: FC = () => (
    <FormattedMessage
        id="useBuyerIncentivesMessages.moneyBackGuarantee"
        defaultMessage="Money-Back Guarantee"
    />
);

const VettedSellers: FC = () => (
    <FormattedMessage
        id="useBuyerIncentivesMessages.vettedSellers"
        defaultMessage="Vetted Professional Sellers"
    />
);

const PriceMatch: FC = () => (
    <FormattedMessage
        id="useBuyerIncentivesMessages.priceMatch"
        defaultMessage="Price-Match Guarantee"
    />
);

const GlobalDelivery: FC = () => (
    <FormattedMessage
        id="useBuyerIncentivesMessages.globalDelivery"
        defaultMessage="Trusted Global Delivery"
    />
);

export const useBuyerIncentivesMessages = ({
    useAll = false,
    variant = 'control',
}: {
    useAll?: boolean;
    variant?: 'control' | 'variant-1' | 'variant-2' | 'variant-3';
} = {}): ReactNode[] => {
    let coreIncentives = [<AuthenticityGuarantee />, <MoneyBack />, <Cancellation />];

    if (variant === 'variant-1') {
        coreIncentives = [<AuthenticityGuarantee />, <MoneyBack />];
    } else if (variant === 'variant-2') {
        coreIncentives = [<AuthenticityGuarantee />, <MoneyBack />, <PriceMatch />];
    } else if (variant === 'variant-3') {
        coreIncentives = [
            <AuthenticityGuarantee />,
            <MoneyBack />,
            <Cancellation />,
            <PriceMatch />,
        ];
    }

    if (!useAll) {
        return coreIncentives;
    }

    return [...coreIncentives, <VettedSellers />, <PriceMatch />, <GlobalDelivery />];
};
